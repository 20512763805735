<template>
  <div id="plantilla" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container fluid class="base text-center">
      <v-row no-gutters class="titulo ml-10 mt-5 text-uppercase"
        >INCIDENCIA POR {{ causaNombre.causa }}</v-row
      >
      <v-row no-gutters justify="end" class="mr-12 mt-n5">
        <v-col cols="12" lg="6" md="6" sm="10" xs="12">
          <v-card-title>
            <v-text-field
              class="mr-2"
              v-model="search"
              append-icon="search"
              label="Buscador"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
        </v-col>
      </v-row>
      <v-row no-gutters class="datos pa-0 mt-1" style="height: auto !important">
        <v-card style="width: 100%; background: rgb(199, 195, 199) !important">
          <v-data-table
            :headers="headers"
            :items="ausentismos"
            :search="search"
            class="elevation-2 text--dark"
            loading-text="Cargando datos... Por favor espere"
            :items-per-page="5"
            :no-results-text="'No se encontraron incidencias'"
            :no-data-text="'No hay datos'"
            :footer-props="{
              'items-per-page-text': 'Empleados por página',
              'items-per-page': [5, 10, 15],
              'items-per-page-all-text': 'Todos',
              'items-per-page-options': [5],
              'loading-text': 'Obteniendo datos... Por favor espere',
              'no-data-text': 'No hay datos...',
              'no-results-text': 'No se encontraron incidencias',
            }"
          >
            <template v-slot:no-data>
              <v-alert :value="true" color="#ffffff" icon="warning">
                <p style="color: black">No hay datos en sistema ...</p>
              </v-alert>
            </template>
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="item in items"
                  :key="item.empleadoId"
                  :class="{ selectedRow: item === selectedItem }"
                >
                  <td class="text-sm-center">{{ item.empleadoId }}</td>
                  <td class="text-sm-center">
                    {{
                      item.empleado.nombre +
                      " " +
                      item.empleado.apellidoPaterno +
                      " " +
                      item.empleado.apellidoMaterno
                    }}
                  </td>
                  <td class="text-sm-center">
                    {{ item.empleado.puesto.nombrePuesto }}
                  </td>
                  <td class="text-sm-center">
                    {{ item.empleado.area.nombreArea }}
                  </td>
                  <td class="text-sm-center">{{ item.empleado.nss }}</td>
                  <td class="text-sm-center">
                    {{ item.fechaAusentismoInicio.substr(0, 10) }}
                  </td>
                  <td class="text-sm-center">
                    {{ item.fechaReincorporacion.substr(0, 10) }}
                  </td>
                  <td class="text-sm-center">
                    {{ item.viaComunicacion.viaComunicacion }}
                  </td>
                  <td class="text-sm-center">{{ item.comentario }}</td>
                  <td class="text-sm-center" v-if="item.documentos.length < 3">
                    <v-col
                      v-if="item.documentos.length == 1"
                      cols="12"
                      sm="12"
                      md="12"
                      lg="12"
                    >
                      <v-img
                        max-height="125"
                        max-width="125"
                        :src="
                          item.documentos[0].empleadoDocumentos.urlDocumento
                        "
                        @click="
                          abrirDocumento(
                            item.documentos[0].empleadoDocumentos.urlDocumento
                          )
                        "
                      ></v-img>
                    </v-col>
                    <v-row v-else>
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        lg="6"
                        v-for="foto in item.documentos"
                        :key="foto.empleadoDocumentosId"
                      >
                        <v-img
                          max-height="125"
                          max-width="125"
                          :src="foto.empleadoDocumentos.urlDocumento"
                          @click="
                            abrirDocumento(foto.empleadoDocumentos.urlDocumento)
                          "
                        ></v-img> </v-col
                    ></v-row>
                  </td>
                  <td class="text-sm-center" v-else>
                    <span
                      v-for="foto in item.documentos"
                      :key="foto.empleadoDocumentosId"
                    >
                      <a :ref="foto.empleadoDocumentos.urlDocumento">{{
                        foto.empleadoDocumentos.urlDocumento
                      }}</a>
                    </span>
                  </td>
                  <td class="text-sm-center">
                    <span
                      v-if="item.estatusId === 1"
                      class="pendiente fa-2x ml-n3"
                      @click="aplicar(item)"
                    >
                      <font-awesome-icon icon="clock" />
                    </span>
                    <span
                      v-if="item.estatusId === 2"
                      class="activado fa-2x ml-n3"
                      @click="calificado(item)"
                    >
                      <font-awesome-icon icon="check-circle" />
                    </span>
                  </td>
                  <td class="text-sm-center">{{ item.estatus.estatus }}</td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </v-row>
    </v-container>
    <!--calificacion item-->
    <v-dialog v-model="calificacion" width="350">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Calificación</span>
        </v-card-title>
        <v-card-text>
          <!--EMPRESA-->
          <v-row>
            <v-col cols="12" sm="4" md="4" lg="4">
              <label for="Aplica">Aplica</label></v-col
            >
            <v-col cols="12" sm="8" md="8" lg="8">
              <select
                name="Aplica"
                id="Aplica"
                v-model="calificacionItem.viaComunicacionId"
                class="mt-n6"
                style="width: 170px !important"
                disabled
              >
                <option
                  v-for="aplicacion in aplicaciones"
                  :key="aplicacion.id"
                  v-bind:value="aplicacion.id"
                >
                  {{ aplicacion.aplicacion }}
                </option>
              </select>
            </v-col>
          </v-row>
          <!--NOMBRE DE AREA-->
          <v-row>
            <v-col cols="12" sm="4" md="4" lg="4">
              <label for="comentario">Comentario</label></v-col
            >
            <v-col cols="12" sm="8" md="8" lg="8">
              <textarea
                type="text"
                name="comentario"
                id="comentario"
                placeholder="Obligatorio"
                class="inputs"
                style="width: 170px !important"
                autocomplete="false"
                disabled
                v-model="comentario"
              />
            </v-col>
          </v-row>
          <!--NOMBRE CORTO-->
          <v-row>
            <v-col cols="12" sm="4" md="4" lg="4">
              <label for="autorizo">Califico Autorización</label></v-col
            >
            <v-col cols="12" sm="8" md="8" lg="8">
              <input
                type="text"
                name="autorizo"
                id="autorizo"
                placeholder="Obligatorio"
                class="inputs"
                autocomplete="false"
                disabled
                v-model="empleadoCalificaId"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            text
            @click="
              calificacionItem = {};
              empleadoCalificaId = 0;
              comentario = '';
              calificacion = false;
            "
            >Aceptar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Editar item-->
    <v-dialog v-model="edit" width="350">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Pendiente por Calificar</span>
        </v-card-title>
        <v-card-text>
          <!--EMPRESA-->
          <v-row>
            <v-col cols="12" sm="4" md="4" lg="4">
              <label for="Aplica">Aplica</label></v-col
            >
            <v-col cols="12" sm="8" md="8" lg="8">
              <select
                name="Aplica"
                id="Aplica"
                v-model="aplica"
                class="mt-n6"
                style="width: 170px !important"
              >
                <option selected disabled value="0">Seleccione</option>
                <option
                  v-for="aplicacion in aplicaciones"
                  :key="aplicacion.id"
                  v-bind:value="aplicacion.id"
                >
                  {{ aplicacion.aplicacion }}
                </option>
              </select>
            </v-col>
          </v-row>
          <!--NOMBRE DE AREA-->
          <v-row>
            <v-col cols="12" sm="4" md="4" lg="4">
              <label for="comentario">Comentario</label></v-col
            >
            <v-col cols="12" sm="8" md="8" lg="8">
              <textarea
                type="text"
                name="comentario"
                id="comentario"
                placeholder="Obligatorio"
                class="inputs"
                style="width: 170px !important"
                autocomplete="false"
                required
                maxlength="180"
                v-model="comentario"
                @keypress="alfaNumerico($event)"
              />
            </v-col>
          </v-row>
          <!--NOMBRE CORTO-->
          <v-row>
            <v-col cols="12" sm="4" md="4" lg="4">
              <label for="autorizo">Califico Autorización</label></v-col
            >
            <v-col cols="12" sm="8" md="8" lg="8">
              <input
                type="text"
                name="autorizo"
                id="autorizo"
                placeholder="Obligatorio"
                class="inputs"
                autocomplete="false"
                disabled
                maxlength="30"
                @keypress="alfaNumerico($event)"
                v-model="person"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="red"
            text
            @click="
              edit = false;
              aplica = 0;
              comentario = '';
            "
            >Cancelar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="aviso()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Aceptación de acciones-->
    <v-dialog v-model="advice" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">Aviso</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 style="text-align: center">{{ respuesta }}</h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="red"
            text
            @click="
              advice = false;
              aplica = 0;
              comentario = '';
            "
            >Cancelar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="guardar()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Ausentismo</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-lg-center text-sm-center text-md-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="confirmation = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Confirmación de datos-->
    <v-dialog v-model="documento" width="400" @click="active = true">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Documento presentado</span>
        </v-card-title>
        <v-card-text class="mt-4">
          <v-img :src="url"></v-img>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" target="_blank" :href="url" download>Descargar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      show: false,
      documento: false,
      causaNombre: {},
      expiration: false,
      search: "",
      dialog: false,
      selectedItem: null,
      comentario: "",
      aplica: 0,
      person: localStorage.nombreEmpleadoGlobal,
      persona: localStorage.empleadoIdGlobal,
      headers: [
        {
          text: "Id Empleado",
          align: "center",
          value: "empleadoId",
          sortable: false,
        },
        {
          text: "Nombre",
          align: "center",
          value: "nombre",
        },
        {
          text: "Puesto",
          align: "center",
          value: "puesto",
        },
        {
          text: "Área",
          align: "center",
          value: "area",
        },
        {
          text: "NSS",
          align: "center",
          value: "nss",
        },
        {
          text: "Fecha de Ausentismo",
          align: "center",
          value: "fechaAusentismoInicio",
        },
        {
          text: "Fecha de Reincoporación",
          align: "center",
          value: "fechaReincorporacion",
        },
        {
          text: "Vía Comunicación",
          align: "center",
          value: "viaComunicacion",
        },
        {
          text: "Comentario",
          align: "center",
          value: "comentario",
        },
        {
          text: "Documento",
          align: "center",
          value: "documento",
        },
        {
          text: "Calificar",
          align: "center",
          value: "calificar",
        },
        {
          text: "Estatus",
          align: "center",
          value: "estatus",
        },
      ],
      ausentismos: [],
      respuesta: "",
      advice: false,
      id: "",
      confirmation: false,
      edit: false,
      advice: false,
      enterprise: localStorage.empresaIdGlobal,
      aplicaciones: [],
      causaId: 0,
      url: "",
      calificacionItem: {},
      calificacion: false,
      empleadoCalificaId: 0,
    };
  },
  methods: {
    onClickOutside() {
      this.active = false;
    },
    alfaNumerico: function (evt) {
      var regex = new RegExp("^[a-zA-ZÀ-ÿ\u00f1\u00d10-9 ]+$"); // Letras,espacio y números
      var str = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (regex.test(str)) {
        return true;
      }
      evt.preventDefault();
      return false;
    },
    aviso() {
      this.edit = false;
      this.respuesta = "¿Esta seguro de guardar su calificación?";
      this.advice = true;
    },
    abrirDocumento(url) {
      this.url = url;
      this.documento = true;
    },
    guardar() {
      this.advice = false;
      this.show = true;
      axios
        .post(
          Server + "/ausentismosLaborales/calificar-ausencia/" + this.id,
          {
            AplicacionId: parseInt(this.aplica),
            Comentario: this.comentario,
            EmpleadoCalificaId: parseInt(this.persona),
          },
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          this.aplica = 0;
          this.comentario = "";
          this.persona = "";
          this.respuesta = "La calificación fue otorgada con éxito.";
          this.listar();
          this.show = false;
          this.confirmation = true;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.aplica = 0;
            this.comentario = "";
            this.persona = "";
            this.respuesta =
              "La calificación no fue otorgada, vuelva a intentarlo.";
            this.listar();
            this.confirmation = true;
          }
        });
    },
    aplicar(item) {
      this.id = item.id;
      this.edit = true;
    },
    calificado(item) {
      this.calificacionItem = item;
      if (this.persona == item.calificacion.empleadoCalificaId) {
        this.empleadoCalificaId = this.person;
      }
      this.comentario = item.calificacion.comentario;
      this.calificacion = true;
    },
    ausentismoAplicaciones() {
      this.show = true;
      axios
        .get(Server + "/ausentismosLaborales/catalogo-aplicaciones", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.aplicaciones = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    listar() {
      this.show = true;
      axios
        .get(
          Server +
            "/ausentismosLaborales/por-causa/" +
            this.enterprise +
            "/" +
            this.causaId +
            "/",
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          //console.log(response);
          this.ausentismos = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    nombreAusentismo() {
      this.show = true;
      axios
        .get(Server + "/ausentismosLaborales/catalogo-causas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.causasAux = response.data;
          this.causaNombre = response.data.find(
            (causa) => causa.id == this.causaId
          );
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
  },
  created() {
    this.ausentismoAplicaciones();
    this.causaId = this.$route.params.causaId;
    this.listar();
    this.nombreAusentismo();
  },
};
</script>